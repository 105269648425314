import { useIntl } from 'react-intl'

type CurrencyNumberBaseProps = {
  value: number
  className: string
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero'
}

type CurrencyNumberProps = Omit<CurrencyNumberBaseProps, 'className'>

function CurrencyNumberBase(props: CurrencyNumberBaseProps) {
  const intl = useIntl()

  const [integerDigits, fractionDigits] = intl
    .formatNumber(props.value, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })
    .split('.')

  return (
    <span className={props.className}>
      <span className="CurrencyNumber-integerDigits">{integerDigits}</span>
      {fractionDigits && fractionDigits.length > 0 && (
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        <span className="CurrencyNumber-fractionDigits">.{fractionDigits}</span>
      )}
    </span>
  )
}

function CurrencyNumber(props: CurrencyNumberProps) {
  return (
    <CurrencyNumberBase
      value={props.value}
      signDisplay={props.signDisplay}
      className="CurrencyNumber"
    />
  )
}

function CurrencyNumberWhite(props: CurrencyNumberProps) {
  return (
    <CurrencyNumberBase
      value={props.value}
      className="CurrencyNumber CurrencyNumber--white"
    />
  )
}

CurrencyNumber.Base = CurrencyNumberBase
CurrencyNumber.White = CurrencyNumberWhite

export default CurrencyNumber
