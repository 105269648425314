import { ComponentProps } from 'react'
import { IconCross } from 'shared/icons'
import useFeature from 'shared/useFeature'
import classNames from 'classnames'

export default function ExitBtn(props: ComponentProps<'button'>) {
  const hideExitButton = useFeature('hide-exit-button')

  return (
    <button
      type="button"
      data-e2e="exit-btn"
      className={classNames(
        { invisible: hideExitButton },
        'z-10 flex h-12 w-12 items-center justify-center'
      )}
      {...props}
    >
      <IconCross className="h-5 w-5" />
    </button>
  )
}
