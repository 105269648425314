import { HTMLMotionProps, motion } from 'framer-motion'
import { FC, forwardRef, RefAttributes } from 'react'
import classNames from 'classnames'
import { addClassName } from '../../shared/compound'
import useFeature from 'shared/useFeature'

export type ActionBarProps = HTMLMotionProps<'div'> &
  RefAttributes<HTMLDivElement>

export interface ActionBarStickyProps extends ActionBarProps {
  stickyOn: boolean
}

interface WithClassName {
  className?: string
}

// Base
const Base = forwardRef<HTMLDivElement, ActionBarProps>((props, ref) => {
  const suppressAnimation = useFeature('dev-suppress-page-animation')
  return (
    <motion.div
      ref={ref}
      initial={suppressAnimation ? undefined : { opacity: 0 }}
      exit={suppressAnimation ? undefined : { opacity: 0 }}
      animate={suppressAnimation ? undefined : { opacity: 1 }}
      {...props}
      className={classNames(props.className, '-mx-margin z-20 px-2')}
    >
      {props.children}
    </motion.div>
  )
})

const StickyBase = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    const suppressAnimation = useFeature('dev-suppress-page-animation')
    const { stickyOn, ...restProps } = props
    return (
      <div ref={ref}>
        {/* Sticky OFF */}
        <Base
          initial={
            suppressAnimation
              ? undefined
              : {
                  opacity: 1,
                }
          }
          animate={
            suppressAnimation
              ? undefined
              : {
                  opacity: stickyOn ? 0 : 1,
                  transition: { duration: 0.1 },
                }
          }
          style={{ zIndex: 1 }}
          {...restProps}
          className={classNames(
            props.className,
            'tbm:px-9 desktop:px-[114px] desktop:py-2'
          )}
        />

        {/* Sticky ON */}
        <BorderB>
          <Absolute>
            <BoxTransclucent>
              <Base
                initial={
                  suppressAnimation
                    ? undefined
                    : {
                        opacity: 0,
                      }
                }
                animate={
                  suppressAnimation
                    ? undefined
                    : {
                        opacity: stickyOn ? 1 : 0,
                        transition: { duration: 0.15 },
                      }
                }
                style={{
                  zIndex: 20,
                  backgroundColor: 'rgba(var(--color-neutral0), 0.84)',
                }}
                {...restProps}
                className={classNames(
                  props.className,
                  'pt-safe left-0 top-0 mbs:mx-0',
                  'tbm:px-9 desktop:px-[114px] desktop:py-2'
                )}
              />
            </BoxTransclucent>
          </Absolute>
        </BorderB>
      </div>
    )
  }
)

const FixedBase = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    const suppressAnimation = useFeature('dev-suppress-page-animation')
    const backgroundColor = 'rgba(var(--color-neutral0), 0.84)'
    const { stickyOn, ...restProps } = props

    return (
      <div
        className="pt-safe -mx-margin fixed top-0 z-10 w-full text-neutral100"
        ref={ref}
      >
        <Base
          initial={
            suppressAnimation
              ? undefined
              : {
                  opacity: 1,
                  backgroundColor: backgroundColor,
                }
          }
          animate={
            suppressAnimation
              ? undefined
              : {
                  backgroundColor: stickyOn
                    ? backgroundColor
                    : 'rgba(var(--color-neutral0)), 0)',
                  transition: { duration: 0.15 },
                }
          }
          style={{
            zIndex: 20,
            transition: 'border-color 0.15s',
          }}
          {...restProps}
          className={classNames(
            props.className,
            stickyOn ? 'border-neutral10' : 'border-transparent',
            'px-margin-only-tablet left-0 top-0 border-b backdrop-blur backdrop-filter mbs:mx-0'
          )}
        />
      </div>
    )
  }
)

// Modifiers
const Absolute: FC<WithClassName> = (props) => {
  return addClassName(['absolute w-full', props.className], {
    node: props.children,
  })
}

const Desktop: FC<WithClassName> = (props) => {
  const desktopClassName = [
    'tbm:bg-transparent tbm:absolute tbm:w-full',
    'tbm:top-0 tbm:left-0',
    'tbm:mx-0 tbm:px-9 desktop:px-[114px] desktop:py-2',
  ]

  return addClassName([...desktopClassName, props.className], {
    node: props.children,
  })
}

const Tablet: FC<WithClassName> = (props) => {
  const desktopClassName = [
    'tbs:bg-transparent tbs:absolute tbs:w-full',
    'tbs:top-0 tbs:left-0',
    'tbs:mx-0 tbm:px-9 desktop:px-[114px] desktop:py-2',
  ]

  return addClassName([...desktopClassName, props.className], {
    node: props.children,
  })
}

const BoxWhite: FC<WithClassName> = (props) => {
  return addClassName(['bg-neutral0 text-neutral100', props.className], {
    node: props.children,
  })
}

const BoxTransparent: FC<WithClassName> = (props) => {
  return addClassName(['bg-transparent text-neutral0', props.className], {
    node: props.children,
  })
}

const BoxTransclucent: FC<WithClassName> = (props) => {
  return addClassName(['backdrop-filter backdrop-blur', props.className], {
    node: props.children,
  })
}

const BorderB: FC<WithClassName> = (props) => {
  return addClassName(['border-neutral10 border-b', props.className], {
    node: props.children,
  })
}

// Variants
const ActionBar: any = forwardRef<HTMLDivElement, ActionBarProps>(
  (props, ref) => {
    return (
      <BoxWhite>
        <Desktop>
          <Base ref={ref} {...props} />
        </Desktop>
      </BoxWhite>
    )
  }
)

const ActionBarHover = forwardRef<HTMLDivElement, ActionBarProps>(
  (props, ref) => {
    return (
      <BoxTransparent>
        <Desktop>
          <Absolute>
            <Base
              ref={ref}
              {...props}
              className={classNames(props.className, 'z-20')}
            />
          </Absolute>
        </Desktop>
      </BoxTransparent>
    )
  }
)

const ActionBarSticky = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    return (
      <BoxWhite>
        <StickyBase ref={ref} {...props} />
      </BoxWhite>
    )
  }
)

ActionBarSticky.displayName = 'ActionBarSticky'

const ActionBarFixed = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    return <FixedBase ref={ref} {...props} />
  }
)

const ActionBarStickyDesktop = forwardRef<HTMLDivElement, ActionBarStickyProps>(
  (props, ref) => {
    return (
      <BoxWhite>
        <Desktop>
          <StickyBase ref={ref} {...props} />
        </Desktop>
      </BoxWhite>
    )
  }
)

const ActionBarFullScreen: any = forwardRef<HTMLDivElement, ActionBarProps>(
  (props, ref) => {
    return (
      <BoxWhite>
        <Tablet>
          <Base ref={ref} {...props} />
        </Tablet>
      </BoxWhite>
    )
  }
)

interface ActionBarFC extends FC<ActionBarProps> {
  Hover: FC<ActionBarProps>
  Sticky: FC<ActionBarStickyProps>
  Fixed: FC<ActionBarStickyProps>
  StickyDesktop: FC<ActionBarStickyProps>
  FullScreen: FC<ActionBarProps>
  Base: FC<ActionBarProps>
  _Base: FC<ActionBarProps>
  _Absolute: FC<ActionBarProps>
  _Desktop: FC<ActionBarProps>
  _BoxWhite: FC<ActionBarProps>
  _BoxTransparent: FC<ActionBarProps>
  _BoxTransclucent: FC<ActionBarProps>
  _BorderB: FC<ActionBarProps>
}

ActionBar.Hover = ActionBarHover
ActionBar.Sticky = ActionBarSticky
ActionBar.Fixed = ActionBarFixed
ActionBar.StickyDesktop = ActionBarStickyDesktop
ActionBar.FullScreen = ActionBarFullScreen
ActionBar.Base = Base

ActionBar._Base = Base
ActionBar._Absolute = Absolute
ActionBar._Desktop = Desktop
ActionBar._BoxWhite = BoxWhite
ActionBar._BoxTransparent = BoxTransparent
ActionBar._BoxTransclucent = BoxTransclucent
ActionBar._BorderB = BorderB

export default ActionBar as ActionBarFC
