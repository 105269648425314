import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  RefObject,
} from 'react'
import throttle from 'lodash/throttle'
import { IconChevronDown } from '../../shared/icons'
import * as smoothscroll from 'smoothscroll-polyfill'
import { motion } from 'framer-motion'

type Context = [boolean, () => void]

const ScrollToBottomContext = createContext<Context>([true, () => {}])

function useScrollToBottom() {
  return useContext(ScrollToBottomContext)
}

export default function ScrollToBottomBtn() {
  const [visible, scrollToBottom] = useScrollToBottom()

  return (
    <motion.div
      className="pb-safe fixed bottom-6 right-6"
      onClick={scrollToBottom}
      animate={{
        opacity: visible ? 1 : 0,
      }}
      transition={{
        duration: 0.1,
      }}
    >
      <div className="inline-flex h-11 w-11 items-center justify-center rounded-full bg-primary10 bg-opacity-80 backdrop-blur-sm backdrop-filter">
        <IconChevronDown />
      </div>
    </motion.div>
  )
}

interface ScrollToBottomProviderProps {
  containerRef: RefObject<HTMLElement>
  children: ReactNode
}

export function ScrollToBottomProvider(props: ScrollToBottomProviderProps) {
  const [visible, setVisible] = useState(true)
  const scrollToBottom = useCallback(() => {
    const container = props.containerRef?.current
    if (container) {
      container.scroll({
        top: container.scrollTop + window.innerHeight - 30,
        behavior: 'smooth',
      })
    }
  }, [props.containerRef])

  const context = useMemo<Context>(() => {
    return [visible, scrollToBottom]
  }, [visible, scrollToBottom])

  useEffect(() => {
    const container = props.containerRef.current
    const handleScroll = throttle(() => {
      if (container) {
        if (
          container.scrollTop + window.innerHeight + 200 >
          container.scrollHeight
        ) {
          setVisible(false)
        } else {
          setVisible(true)
        }
      }
    }, 50)

    if (container) {
      container.addEventListener('scroll', handleScroll)
      smoothscroll.polyfill()
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll, false)
      }
    }
  }, [props.containerRef])

  return (
    <ScrollToBottomContext.Provider value={context}>
      {props.children}
    </ScrollToBottomContext.Provider>
  )
}
