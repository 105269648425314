import { HTMLMotionProps, motion } from 'framer-motion'
import { ReactNode, RefObject, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import omit from 'lodash/omit'
import { ScrollLockProvider } from '../../shared/useScrollLock'
import { AtomicHistory } from '../../shared/createAtomicHistory'
import useFeature from '../../shared/useFeature'
import usePageAnalytics from '../../shared/usePageAnalytics'
import PageErrorBoundary from '../PageErrorBoundary'
import { ScrollToBottomProvider } from './ScrollToBottomBtn'

const transition = {
  type: 'spring',
  bounce: 0,
  duration: 0.4,
}

const variants = {
  exit: (action: any) => {
    return {
      x: action === 'back' ? '100%' : '-100%',
      transition,
    }
  },

  enter: () => {
    return {
      x: 0,
      transition,
    }
  },
}

export interface PageProps extends HTMLMotionProps<'div'> {
  containerRef?: any
  suppressAnimation?: boolean
}

function Page({ containerRef, suppressAnimation, ...divProps }: PageProps) {
  useAnalytics(divProps.id ?? '-')
  const children = (
    <PageErrorBoundary>
      <ScrollLockProvider>
        <Body containerRef={containerRef}>{divProps.children}</Body>
      </ScrollLockProvider>
    </PageErrorBoundary>
  )

  return suppressAnimation ? (
    <div
      className={`absolute inset-0 ${divProps.className}`}
      {...(divProps.id && { 'data-e2e': divProps.id })}
    >
      {children}
    </div>
  ) : (
    <AnimationContainer {...divProps}>{children}</AnimationContainer>
  )
}

function PageFullScreen(props: PageProps) {
  useAnalytics(props.id ?? '-')
  return (
    <AnimationContainer {...omit(props, 'children')}>
      <PageErrorBoundary>
        <ScrollLockProvider>
          <FullScreenBody>{props.children}</FullScreenBody>
        </ScrollLockProvider>
      </PageErrorBoundary>
    </AnimationContainer>
  )
}

function AnimationContainer(props: HTMLMotionProps<'div'>) {
  const { children, className, ...restProps } = props
  const suppressAnimation = useFeature('dev-suppress-page-animation')
  const history = useHistory() as AtomicHistory
  const animation = history.animation
  return (
    <motion.div
      initial={
        !suppressAnimation
          ? {
              // prettier-ignore
              x: (!history || history.length === 1)
              ? undefined
              : animation === 'back' ? '-100%' : '100%',
            }
          : undefined
      }
      exit={suppressAnimation ? undefined : 'exit'}
      animate={suppressAnimation ? undefined : 'enter'}
      variants={variants}
      {...restProps}
      {...(props.id && { 'data-e2e': props.id })}
      className={`absolute inset-0 ${className}`}
    >
      {children}
    </motion.div>
  )
}

interface BodyProps {
  children: ReactNode
  containerRef?: RefObject<HTMLDivElement>
}

function Body(props: BodyProps) {
  const internalRef = useRef<HTMLDivElement>(null)
  const ref = props.containerRef || internalRef
  return (
    <ScrollToBottomProvider containerRef={ref}>
      <div
        ref={ref}
        className="scrollbar-hidden inset-0 h-full overflow-x-hidden tbm:w-full"
      >
        <div className="pt-safe pr-safe pb-safe pl-safe tbm:h-full">
          <div className="px-margin pb-6 tbs:pb-24 tbm:h-full tbm:pb-0">
            {props.children}
          </div>
        </div>
      </div>
    </ScrollToBottomProvider>
  )
}

function FullScreenBody(props: BodyProps) {
  return (
    <div className="pt-safe pr-safe pb-safe pl-safe h-full w-full overflow-hidden">
      <div className="px-margin h-full">
        <div className="flex h-full flex-col tbs:mx-auto tbs:max-w-column tbm:max-w-none tbm:items-center tbm:justify-center">
          {props.children}
        </div>
      </div>
    </div>
  )
}

function useAnalytics(pageId: string) {
  const dispatch = usePageAnalytics()
  useEffect(() => {
    dispatch({ type: 'OPEN_PAGE', pageId })
    return () => dispatch({ type: 'CLOSE_PAGE', pageId })
  }, [pageId, dispatch])
}

Page.FullScreen = PageFullScreen

export default Page
