import { HTMLProps } from 'react'

export default function FSLayer(props: HTMLProps<HTMLDivElement>) {
  return (
    <div
      className="pointer-events-none absolute left-0 top-0 h-full w-full overflow-hidden"
      {...props}
    />
  )
}
