import { DefaultThemeProvider } from '../useTheme'

export default function ConfigErrorsPage({ errors }: { errors: any }) {
  return (
    <DefaultThemeProvider>
      <div className="absolute h-screen w-screen bg-neutral0">
        <div className="mx-5 tbm:mx-10">
          <div className="mx-auto mt-10 max-w-[700px] rounded-md bg-warning25 p-4 tbm:p-10">
            <div className="tbm:text-h3">⚠️ Module configuration invalid</div>
            <div className="ml-6 mt-6 tbm:ml-10 tbm:mt-10">
              <div>
                The Atomic Module cannot be opened because the following
                validation failed:
                <ul className="ml-6 list-disc">
                  {errors.emptyOrigin && (
                    <li>
                      The field{' '}
                      <span className="rounded-sm bg-neutral50 px-0.5 font-serif text-sm tracking-wider opacity-75">
                        origin
                      </span>{' '}
                      was not set
                    </li>
                  )}
                  {errors.emptyToken && (
                    <li>
                      The field{' '}
                      <span className="rounded-sm bg-neutral50 px-0.5 font-serif text-sm tracking-wider opacity-75">
                        token
                      </span>{' '}
                      was not set
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultThemeProvider>
  )
}
