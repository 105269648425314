import { ActionBar, ExitBtn } from '.'

interface ErrorPageProps {
  error: Error
  onExit: () => void
}

export default function ErrorPage({ onExit }: ErrorPageProps) {
  return (
    <div className="pl-safe pr-safe relative h-screen w-screen">
      <div className="pt-safe pr-safe pb-safe pl-safe h-full w-full overflow-hidden">
        <div className="px-margin h-full">
          <ActionBar className="flex justify-end">
            <ExitBtn onClick={onExit} />
          </ActionBar>

          {/* ErrorPage is created before InltProvider is setup. */}
          {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
          <div className="mt-64 text-center font-sans text-neutral200">
            Something went wrong. Please try again later.
          </div>
        </div>
      </div>
    </div>
  )
}
